<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h3>Add teacher</h3>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                <router-link :to="{ name: 'teachers' }"> Teachers </router-link>
                \ Add teacher
              </div>
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <v-col cols="12">
                Personal Information
                <hr />
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  dense
                  item-value="value"
                  item-text="text"
                  :items="titles"
                  v-model="user.title"
                  label="Title"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  v-model="user.first_name"
                  label="First name"
                ></v-text-field>
                <span class="text-danger" v-if="$v.user.first_name.$error"
                  >This information is required</span
                >
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  v-model="user.middle_name"
                  label="Middle name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  v-model="user.last_name"
                  label="Last name"
                ></v-text-field>
                <span class="text-danger" v-if="$v.user.last_name.$error"
                  >This information is required</span
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="user.dob"
                      label="Date of birth"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="user.dob"
                    @change="convertAdToBs"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                Contact Information
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  v-model="user.mobile"
                  label="Mobile"
                  @blur="validatePhone"
                ></v-text-field>
                <span
                  class="text-danger"
                  v-if="!this.id && $v.user.mobile.$error"
                  >This information is required</span
                >
                <span class="text-danger" v-if="phone_exists"
                  >Mobile number already taken</span
                >
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  v-model="user.phone"
                  label="Phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                Login Information
                <hr />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  v-model="user.email"
                  @blur="validateEmail"
                  label="Email"
                ></v-text-field>
                <span
                  class="text-danger"
                  v-if="!this.id && $v.user.email.$error"
                  >This information is required</span
                >
                <span class="text-danger" v-if="email_exists"
                  >Email already taken</span
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-switch
                  v-model="user.is_email_verified"
                  label="Is this email address verified for login and communication purpose?"
                >
                </v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <div v-if="!this.id">
                  <v-text-field
                    type="password"
                    outlined
                    dense
                    v-model="user.password"
                    label="Password"
                  ></v-text-field>
                  <span
                    class="text-danger"
                    v-if="!this.id && $v.user.password.$error"
                    >This information is required</span
                  >
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <div class="" v-if="!this.id">
                  <v-text-field
                    type="password"
                    outlined
                    dense
                    v-model="user.confirm_password"
                    label="Confirm password"
                  ></v-text-field>
                  <span
                    class="text-danger"
                    v-if="!this.id && $v.user.confirm_password.$error"
                    >Confirm password not matched with password</span
                  >
                </div>
              </v-col>
              <v-col cols="12">
                Faculty Information
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <div class="">
                  <v-select
                    outlined
                    dense
                    v-model="user.department_id"
                    :items="departments"
                    multiple
                    small-chips
                    item-value="id"
                    item-text="title"
                    label="Department"
                  >
                  </v-select>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  :items="programs"
                  item-value="id"
                  item-text="title"
                  v-model="program_ids"
                  multiple
                  small-chips
                  outlined
                  dense
                  label="Program"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  dense
                  v-model="user.description"
                  label="Description"
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="12">
                <v-switch
                  v-model="user.send_welcome_email"
                  label="Send a welcome email to teacher with their login details?"
                >
                </v-switch>
              </v-col>

              <v-col cols="12" md="12">
                <v-switch v-model="user.is_active" label="Account status">
                </v-switch>
              </v-col>
            </div>

            <v-col
              cols="12"
              md="12"
              v-if="user.image_path && user.image_path['thumb']"
            >
              <label>User Image</label> <br />
              <img
                :src="user.image_path['thumb']"
                alt
                style="height: 150px"
                class="img-thumbnail"
              />
            </v-col>

            <div class="row">
              <v-col cols="12" class="my-5 text-right">
                <v-btn
                  v-if="checkIsAccessible('user', 'create')"
                  class="text-white ml-2 btn btn-primary"
                  depressed
                  @click="createOrUpdate"
                  :loading="isBusy"
                  >Save
                </v-btn>
              </v-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import JobPositionService from "@/core/services/job-position/JobPositionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import DepartmentService from "@/core/services/department/DepartmentService";
import TeacherService from "@/core/services/level/program/teacher/TeacherService";

const userService = new UserService();
const positionService = new JobPositionService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService();
const enrollmentService = new UserEnrollmentService();
const departmentService = new DepartmentService();
const teacherService = new TeacherService();

export default {
  name: "Teacher-create-update",
  validations() {
    return {
      user: this.rules,
    };
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      titles: [
        { text: "Mr", value: "mr" },
        { text: "Mrs", value: "mrs" },
        { text: "Ms", value: "ms" },
        { text: "Miss", value: "miss" },
        { text: "Dr.", value: "dr" },
        { text: "Other", value: "" },
      ],
      email_exists: false,
      phone_exists: false,
      job_types: ["admin", "student", "coordinator", "principle"],
      programs: [],
      department_ids: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      program_ids: [],
      levels: [],
      departments: [],
      levelId: null,
      menu2: false,

      studentSetting: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        class_id: null,
      },
      user: {
        title: "",
        department_ids: [],
        password: null,
        confirm_password: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        mobile: null,
        description: null,
        type: "teacher",
        account_type: "teacher",
        is_email_verified: true,
        is_active: true,
        dob: null,
        send_welcome_email: false,
      },
    };
  },

  mounted() {
    this.id ? this.getUser(this.id) : "";
    this.getDepartments();
    this.getPrograms();
    // this.getLevels()
    // this.getAcademicYears()
    // this.getGrades()
    // this.getAcademicClasses()
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    max_date() {
      let newDate = new Date();
      let year = newDate.getFullYear() - 18;
      let month =
        newDate.getMonth().toString().length == 1
          ? "0" + (newDate.getMonth() + 1)
          : newDate.getMonth();
      let day =
        newDate.getDay().toString().length == 1
          ? "0" + newDate.getDay()
          : newDate.getDay();
      // this.user.dob = `${year}-${month}-${day}`;
      if (this.user.dob) this.convertAdToBs();
      return `${year}-${month}-${day}`;
    },
    min_date() {
      let newDate = new Date();
      return `${newDate.getFullYear() - 50}-01-01`;
    },
    teacherId() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    department_ids_converted() {
      if (
        this.user &&
        this.user.department_id &&
        this.user.department_id.length
      ) {
        return this.user.department_id;
      } else return null;
    },

    rules() {
      let rule = {
        first_name: { required },
        last_name: { required },
        type: { required },
      };
      if (!this.id) {
        rule.email = { required };
        rule.mobile = { required };
        rule.password = { required };
        rule.confirm_password = {
          required,
          sameAsPassword: sameAs("password"),
        };
      }
      return rule;
    },
  },
  methods: {
    getDepartments() {
      departmentService.paginate().then((response) => {
        this.departments = response.data.data;
      });
    },
    syncTeacher(teacherId) {
      if (this.program_ids.length) {
        let data = this.program_ids.map((program_id) => {
          return {
            program_id: program_id,
            user_id: teacherId,
          };
        });
        teacherService
          .syncPrograms(teacherId, data)
          .then((res) => {})
          .catch((err) => {});
      }
    },
    getUser(id) {
      userService.show(id).then((response) => {
        this.user = response.data.user;
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
          ? (this.user.is_email_verified = true)
          : false;
        this.studentSetting = this.user.setting;
        if (this.user.department_id)
          this.user.department_id = this.user.department_id
            .split(",")
            .map((iNum) => parseInt(iNum, 10));
        this.edit = true;
        this.getLevels();
        this.getAssignedPrograms();
      });
    },
    getAssignedPrograms() {
      teacherService
        .getAssignedProgram(this.teacherId)
        .then((res) => {
          this.program_ids = res.data.programs.map((program) => {
            return program.id;
          });
        })
        .catch((err) => {});
    },
    getLevels() {
      levelService.all().then((response) => {
        this.levels = response.data;
        if (this.studentSetting.id && this.studentSetting.level_id) {
          this.getPrograms();
        }
      });
    },
    // getPrograms() {
    //   programService.getAllByLevelId(this.studentSetting.level_id).then(response => {
    //     this.programs = response.data;
    //   });
    // },
    getPrograms() {
      programService.all().then((response) => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then((response) => {
        this.academicYears = response.data;
      });
    },
    getGrades() {
      gradeLevelService
        .getByProgram(this.studentSetting.program_id)
        .then((response) => {
          this.gradesLevels = response.data;
        });
    },
    getAcademicClasses() {
      academicClassService
        .getByProgramLevel(
          this.studentSetting.academic_year_id,
          this.studentSetting.program_id,
          this.studentSetting.grade_id
        )
        .then((response) => {
          this.academic_classes = response.data;
        });
    },
    save(date) {
      this.$refs.menu2.save(date);
    },
    convertAdToBs() {
      let data = {
        dob: this.user.dob,
      };
      enrollmentService
        .convertAdToBs(data)
        .then((response) => {
          this.user.dob_bs = response.data.dob;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    redirectTo() {
      this.$router.push({ name: "teachers" });
    },
    saveTeacher() {
      this.isBusy = true;
      if (this.user.department_id && this.user.department_id.length > 0)
        this.user.department_id = this.user.department_id;
      userService
        .createByAdmin(this.user)
        .then((response) => {
          if (!response.data.status) {
            this.isBusy = false;
            this.$snotify.error(response.data.msg);
            return;
          }
          this.isBusy = false;
          this.$snotify.success("Teacher added to the system");
          this.$tabs.close().then((response) => {
            this.$router.push({ name: "teachers" });
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    createOrUpdate() {
      this.$v.user.$touch();
      if (this.$v.user.$error) {
        setTimeout(() => {
          this.$v.user.$reset();
        }, 3000);
      } else {
        // this.user.setting = this.studentSetting
        if (
          this.user &&
          this.user.department_id &&
          this.user.department_id.length
        ) {
          this.user.department_id;
        }
        if (this.edit) {
          delete this.user.password;
          this.updateTeacher();
        } else {
          this.saveTeacher();
        }
      }
    },
    validateEmail() {
      // 'user/validate-email'
      let data = {
        email: this.user.email,
      };
      userService.validateEmail(data).then((res) => {
        if (res.data.status == "OK" && res.data.user) {
          this.email_exists = true;
          this.$snotify.error("Email Already Exists Please Try New Email ");
        } else {
          this.email_exists = false;
        }
      });
    },
    validatePhone() {
      // 'user/validate-email'
      let data = {
        mobile: this.user.mobile,
      };
      userService.validatePhone(data).then((res) => {
        if (res.data.status == "OK" && res.data.user) {
          this.phone_exists = true;
          this.$snotify.error("Mobile number already in use");
        } else {
          this.phone_exists = false;
        }
      });
    },
    reset() {
      this.user = {
        title: null,
        department_ids: [],
        password: null,
        confirm_password: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        mobile: null,
        description: null,
        type: "teacher",
        account_type: "teacher",
        is_email_verified: true,
        is_active: true,
        send_welcome_email: false,
        dob: null,
      };
      this.$v.user.$reset();
      this.program_ids = [];
    },
    updateTeacher() {
      this.isBusy = true;
      delete this.user.email;
      delete this.user.phone;
      delete this.user.photo;
      this.user.department_id = this.department_ids_converted;
      userService
        .updateUser(this.user.id, this.user)
        .then((response) => {
          this.isBusy = false;
          this.syncTeacher(this.teacherId);
          this.reset();
          this.$snotify.success("Teacher record updated");
          this.$tabs.close().then((response) => {
            this.$router.push({ name: "teachers" });
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Error updating record. Please try again later.");
        });
    },
  },
};
</script>
