import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class TeacherService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/program`;
      }


      paginate(programId) {
            let url = `${this.#api}/${programId}/teacher`;
            return apiService.get(url);
      }

      all(programId) {
            let url = `${this.#api}/${programId}/teacher`;
            return apiService.get(url);
      }

      update(programId, id, data) {
            let url = `${this.#api}/${programId}/teacher/${id}`
            return apiService.put(url, data);

      }

      store(programId, data) {
            let url = `${this.#api}/${programId}/teacher`
            return apiService.post(url, data);

      }

      show(programId, id) {
            let url = `${this.#api}/${programId}/teacher/${id}`
            return apiService.get(url);
      }

      delete(programId, id) {
            let url = `${this.#api}/${programId}/teacher/${id}`
            return apiService.delete(url);
      }
      getAssignedProgram(userId) {
            let url = `${API_URL}user/teacher/${userId}/assigned/programs`;
            return apiService.get(url);
      }
      syncPrograms(userId,data){
            let url = `${API_URL}user/teacher/${userId}/program/sync-teacher-programs`;
            return apiService.post(url, data);
      }
}
